import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'

const app = createApp(App)

app.use(store).use(router).mount('#app');

// const res = await axios.get('https://httpbin.org/basic-auth/foo/bar', {
//     auth: {
//       username: 'foo',
//       password: 'bar'
//     }
// });

// res.status;

// console.log(res.status);