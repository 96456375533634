import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

const routes = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: HomeView,
    //     meta: {
    //         title: 'Главная'
    //     },
    // },
    {
        path: '/',
        name: 'home',
        component: () => { 
        if (store.state.isAuth) {
            return import(/* webpackChunkName: "home" */ '../views/ChatView.vue');
        } else {
            return import(/* webpackChunkName: "app-home" */ '../views/HomeView.vue');
        }
        },
        meta: {
            title: 'Главная'
        },
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: 'О сайте'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title + ' - ' + store.state.apptitle : store.state.apptitle
    next();
})

export default router
