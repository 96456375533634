<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/>
</template>

<!-- <script>
    export default {
        name: 'app',
        props: ['mainTitle'],
        data() {
            return {
                maintitle: this.mainTitle
            }
        }        
    }
</script> -->

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
</style>


